.sidemenu {
  /* position: relative; */
  left: 0px;
  top: 0px;
  width: 19vw;
  height: 100vh;
  padding: 0 10px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    110.9deg,
    #eef7ff 10%,
    rgba(238, 247, 255, 0) 25%,
    rgba(238, 247, 255, 0) 10%
  );

  box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.04);
  background-color: #fff;
}
.dashboard_outlet {
  position: absolute;
  left: 19vw;
  top: 0px;
  background: #fbfbfb;
  /* background: red; */
  height: 100vh;
  width: 81vw;
  overflow: scroll;
  padding: 2rem;
  /* z-index: 99; */
}

.dashboard_outlet .content_wrapper {
  /* position: relative; */
}
.gradient_dashboard_nav svg {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
