@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}
.drcfo_button_white {
  border: 1px solid #194af5;
  border-radius: 8px;
  padding: 4px 15px;
  font-weight: 400;
  font-size: 13px;
  color: #194af5;
  background: #fff;
}
.drcfo_button_light {
  border: none;
  color: #194af5;
  border-radius: 8px;
  padding: 4px 15px;
  font-weight: 400;
  font-size: 13px;
  background: #e6ebff;
}
.drcfo_button_blue {
  border: 1px solid #194af5;
  border-radius: 8px;
  padding: 4px 15px;
  font-weight: 400;
  font-size: 13px;
  color: #f8f8f8;
  background: #194af5;
}
button:active {
  /* transform: scale(1.1) !important; */
  transform: translateY(2px);
}
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #e6ebff;
  border-radius: 50px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* To stop background scrolling of modal */
body.modal-open {
  overflow: hidden;
}
