.prescription_content {
  margin-top: 20px;
}
.prescription_content label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 1rem;
  color: #000000;
  width: 100%;
}
#complain {
  /* background: red; */
  margin-top: 3vh;
}
.table_prescription {
  width: 100%;
  border: 1px solid #d6d6d6;
  background-color: #fff;
  margin-top: 15px;
  margin: 0;
  padding: 0;
}

.table_prescription thead tr th {
  padding: 10px;
  border: 1px solid #d6d6d6;
  font-size: 12px;
  color: #232526;
  font-weight: 600;
  background: #f2f5ff;
}

.table_prescription tr td {
  border: 1px solid #d6d6d6;
  font-size: 12px;
}
.prescription_table_input {
  border: none;
  width: 100%;
}

.prescription_table_input:focus {
  outline: none;
  text-align: left;
}
.prescription_table_input::placeholder {
  font-weight: 400;
  font-size: 14px;
  color: #b7b7b7;
}
.sl_number_box {
  width: 5%;
  padding: 10px;
}
.drugname_input {
  position: relative;
  width: 20%;
  padding: 0 5px;
}
.drugname_input input {
  text-transform: uppercase;
}
.drugtype_box {
  width: 10%;
  padding-right: 10px;
}
.small_box {
  width: 7%;
  padding: 10px;
}
.small_box_generic {
  width: 12%;
  padding: 0 10px;
  height: 80px;
}
.big_box {
  padding: 10px 5px 10px 10px;
  /* background-color: red; */
}
.big_box_ins {
  width: 15%;
  padding: 5px;
}
.test_div {
  gap: 30px;
  margin-bottom: 20px;
}

.addrow_btn {
  background-color: #e6ebff;
  color: #000;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 14px;
}
.addrow_btn2 {
  background-color: #e6ebff;
  color: #000;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 14px;
  width: fit-content;
}
.addrow_btn svg {
  vertical-align: middle;
  margin-right: 8px;
}
.addrow_btn2 svg {
  vertical-align: middle;
  margin-right: 8px;
}
.database_tamplate_cancel {
  width: 90px;
  margin-right: 10px;
  padding: 5px 0;
  background-color: white;
  color: #194af5;
  border: solid 1.5px #194af5;
  border-radius: 4px;
  font-size: 14px;
}
.database_tamplate_save {
  padding: 5px 0;
  background-color: #194af5;
  color: #fff;
  border: solid 1.5px #194af5;
  border-radius: 4px;
  width: 90px;
  font-size: 14px;
}
