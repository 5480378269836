.template_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.template_header h1 {
  margin: 0;
  font-size: 1.2rem;
}
.template_header button {
  background: #194af5;
  border: none;
  padding: 10px 20px;
  font-size: 0.8rem;
  color: #fff;
  border-radius: 8px;
}
.category_card {
  background: #f2f5ff;
  border-radius: 20px;
  padding: 1rem;
  cursor: pointer;
}
.category_card h1 {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0;
}
.category_card p {
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 0;
}
.category_card span {
  font-size: 1.1rem;
  font-weight: 500;
}
.template_modal_header {
  font-size: 1.1rem;
  margin: 0;
  margin-bottom: 15px;
  font-weight: 500;
}
.template_table_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 0.5rem 1rem;
  background: #f9f9f9;
}
.template_table_card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
}
.template_table_card p {
  margin: 0;
  font-weight: 400;
}
.template_table_card svg {
  cursor: pointer;
}
.database_menu_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 0.8rem;
}
